import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useGate } from 'statsig-react';
import { useAuth } from '../../utils/authentication';
import { getDefaultRedirectUrl } from '../../consts/default-redirect-url';
import { DemandPartnerCustomer, Employer } from '../../consts/models';
import { SendFormRequest } from '../../utils/requests';
import LinkTokenForm, { FieldConfigs, OnTokenCreation } from '../Form/LinkTokenForm';
import { getOption } from '../Form/utils';
import FIELDS, {
  formHasWriteJob,
  requiredJobsWithoutCard,
  TestConsolePropGeneratorOptions,
} from './form-configs';
import { ModeKey, shouldEnableAmount } from './form-helpers';
import './TestConsole.scss';
import { LinkTokenFormKey, TLinkTokenForm } from './types';
import { getConfig } from '../../utils/statsig';
import { useSession } from '../../utils/session';
import { getCurrentWorkspace } from '../../utils/identity';

export const getEnv = () => {
  if (window.location.host.includes('localhost')) return 'staging';
  if (window.location.host.includes('staging')) return 'staging';

  return 'production';
};

export const formInitialState: TLinkTokenForm = {
  mode: getOption<ModeKey>('sandbox'),
  orgName: '',
  demandPartnerCustomer: { label: '', value: undefined },
  selectedEmployer: { label: '', value: undefined },
  amount: '',
  skipIntroScreen: false,
  disableDirectDepositSplitting: false,
  requiredJobs: [],
  accountId: '',
  accounts: '',
  platformMatching: '',
  card: '',
  documentUploads: { label: '', value: '' },
  depositForms: { label: '', value: '' },
  platformType: { label: '', value: '' },
  emptyBox: '',
  enableCardSwitch: false,
};

function generateFormInitialState({ workspaceId }): TLinkTokenForm {
  return {
    ...formInitialState,
    redirectUrl: getDefaultRedirectUrl(workspaceId),
  };
}

function filterCardSwitchJobsIfGated(
  fields
): FieldConfigs<TestConsolePropGeneratorOptions, TLinkTokenForm> {
  let filteredFields = fields;
  if (!useGate('ff-card-switch').value) {
    filteredFields.requiredJobs = requiredJobsWithoutCard.requiredJobs;
  }
  return filteredFields;
}

export default function ({
  availableModes,
  onFormChange,
  setEmployerSearchText,
  employers,
  demandPartnerCustomers,
  lastAccountId,
  onTokenCreation: onFormSuccess,
  getSubmitFormFn,
}: {
  availableModes: ModeKey[];
  onFormChange?: Function;
  setEmployerSearchText: (search: string) => void;
  employers: Employer[];
  demandPartnerCustomers: DemandPartnerCustomer[];
  lastAccountId?: string;
  onTokenCreation: OnTokenCreation;
  getSubmitFormFn: (sendFormRequest: SendFormRequest) => (form: TLinkTokenForm) => Promise<any>;
}) {
  const session = useSession();
  const { currentWorkspaceId } = useAuth();

  const [form, setForm] = useState(generateFormInitialState({ workspaceId: currentWorkspaceId }));
  const mode = useMemo(() => {
    return form.mode?.value;
  }, [form.mode]);

  const linkSitesEnabledGate = useGate('ff-link-sites-enabled');
  const REDIRECT_FIELDS: LinkTokenFormKey[][] = linkSitesEnabledGate.value ? [['redirectUrl']] : [];

  const cardSwitchGate = useGate('ff-card-switch');
  const delayedCardInfoGate = useGate('ff-allow-delayed-card-info');
  const enableCardSwitch: LinkTokenFormKey[][] =
    cardSwitchGate.value && delayedCardInfoGate.value ? [['enableCardSwitch']] : [];

  const docUploadsGate = useGate('ff-doc-uploads-customer-facing');
  const directDepositFormsGate = useGate('ff-dd-forms');
  const platformMatchingAllModesGate = useGate('ff-adp-partnership-allow-token-user-data');
  const platformMatchingSandboxOnlyGate = useGate('ff-allow-token-user-data-dev-dashboard-sandbox');

  const shouldShowPlatformMatching = useMemo(() => {
    return (
      platformMatchingAllModesGate?.value ||
      (mode === 'sandbox' && platformMatchingSandboxOnlyGate?.value)
    );
  }, [mode, platformMatchingAllModesGate, platformMatchingSandboxOnlyGate]);

  const workspace = useMemo(() => {
    return getCurrentWorkspace(session.data?.workspaces || [], currentWorkspaceId);
  }, [currentWorkspaceId, session]);

  const FIELD_LAYOUT: LinkTokenFormKey[][] = [
    ['mode', workspace?.isDemandPartnerProxy ? 'demandPartnerCustomer' : 'orgName'],
    ['requiredJobs', 'accountId'],
    ['language', 'endUserId'],
    ['selectedEmployer', 'amount'],
    shouldShowPlatformMatching ? ['platformType', 'emptyBox'] : ['platformType', 'accounts'],
    shouldShowPlatformMatching ? ['accounts', 'platformMatching'] : [],
    docUploadsGate.value && directDepositFormsGate.value
      ? ['documentUploads', 'depositForms']
      : docUploadsGate.value
      ? ['documentUploads', 'emptyBox']
      : directDepositFormsGate.value
      ? ['depositForms', 'emptyBox']
      : [],
    cardSwitchGate.value ? ['card', 'emptyBox'] : [],
    ['skipIntroScreen'],
    ['disableDirectDepositSplitting'],
    ...enableCardSwitch,
    ...REDIRECT_FIELDS,
  ];

  const propGeneratorOptions = {
    availableModes,
    setEmployerSearchText,
    employers,
    lastAccountId,
    workspaceId: currentWorkspaceId,
    demandPartnerCustomers: demandPartnerCustomers || [],
  };

  useEffect(() => {
    if (!shouldEnableAmount(form)) {
      setForm({ ...form, amount: formInitialState.amount });
    }
  }, [form.requiredJobs, form.selectedEmployer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (workspace?.isDemandPartnerProxy && form.requiredJobs && form.requiredJobs.length === 0) {
      setForm({
        ...form,
        requiredJobs: [{ value: 'direct_deposit_switch', label: 'direct_deposit_switch' }],
      });
    }
  }, [workspace, form]);

  useEffect(() => {
    if (!formHasWriteJob(form)) {
      setForm({ ...form, accounts: '' });
    } else {
      setForm({
        ...form,
        accounts: [
          {
            name: 'New account',
            type: 'checking',
            routing_number: form.platformMatching
              ? getConfig('dc-adp-partnership-test-user').bank_info?.routing_number
              : _.times(9)
                  .map(() => Math.floor(Math.random() * 10))
                  .join(''),
            account_number: _.times(10)
              .map(() => Math.floor(Math.random() * 10))
              .join(''),
          },
        ],
      });
    }

    const selectedJobs = form.requiredJobs?.map((x) => x.value) || [];
    if (selectedJobs.includes('card_switch')) {
      setForm({
        ...form,
        card: {
          card_name: 'Fake Name',
          card_number: '5105105105105100',
          cvc: '123',
          expiration_date: '07/24',
        },
      });
    }
  }, [mode, form.requiredJobs, form.platformMatching]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => onFormChange && onFormChange(form), [form, onFormChange]);

  return (
    <>
      <LinkTokenForm<TestConsolePropGeneratorOptions, TLinkTokenForm>
        propGeneratorOptions={propGeneratorOptions}
        form={form}
        setForm={setForm}
        formInitialState={generateFormInitialState({ workspaceId: currentWorkspaceId })}
        fieldConfigs={filterCardSwitchJobsIfGated(FIELDS)}
        layout={FIELD_LAYOUT}
        onFormSuccess={onFormSuccess}
        getSubmitFormFn={getSubmitFormFn}
      />
    </>
  );
}
